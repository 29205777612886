<script setup lang="ts">
const { locale, t, localeProperties } = useI18n();

const { data } = await useAsyncGql({
  operation: 'GetSlugsPageDownload'
});

const allTranslatedSlugs = data.value.page?._allTranslatedSlugLocales;

const downloadLink = computed(function () {
  const item = allTranslatedSlugs?.find((i) => i.locale === localeProperties.value.siteLocale);

  if (item && item.value) {
    return {
      name: `slug___${localeProperties.value.code}`,
      params: {
        slug: item.value
      }
    };
  }

  return {
    name: `slug___de`,
    params: {
      slug: 'download'
    }
  };
});
</script>

<template>
  <div class="download-link">
    <NuxtLink class="link" :to="downloadLink">
      <span class="label">{{ t('downloads') }}</span>
    </NuxtLink>
  </div>
</template>

<style lang="scss">
.download-link {
  position: fixed;

  > .link {
    text-decoration: none;

    &.router-link-exact-active {
      display: none;
    }
  }

  @media (--lg-down) {
    body:has(.site-header-container.-nav-open) & {
      display: flex;
    }

    display: none;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--height-download-button);
    z-index: var(--z-downloadbutton);
    background-color: var(--base-color-brand);
    color: var(--color-white);

    &::before {
      position: absolute;
      top: 50%;
      right: 50px;
      display: inline-block;
      content: '';
      width: 28px;
      height: 28px;
      background: var(--base-color-brand);
      border-radius: 50%;
      border: 1px solid var(--color-white);
      transform: translateY(-50%);
      box-shadow: #00000026 0 0 10px;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 58px;
      display: inline-block;
      content: '';
      width: 12px;
      height: 12px;
      background: var(--color-white);
      mask-image: url('/arrow-download.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      transform: translateY(-50%);
    }

    > .link {
      @include text-extra-small();

      display: block;
      width: 100%;
      text-align: center;

      &:hover,
      &:focus,
      &:active {
        color: inherit;
      }
    }
  }

  @media (--lg-up) {
    bottom: 2rem;
    right: 2rem;
    z-index: var(--z-overlay);
    transition:
      opacity var(--base-transition-duration) var(--base-transition-easing),
      transform var(--base-transition-duration) var(--base-transition-easing);

    > .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 54px;
      min-height: 54px;
      max-height: 54px;
      padding: 1rem;
      background: var(--color-white) 0 0 no-repeat padding-box;
      box-shadow: 0 0 0.625rem #00000026;
      border: 1px solid var(--base-color-brand);
      color: var(--color-white);
      border-radius: 27px;
      text-decoration: none;
      transition: var(--base-transition-all);

      @media (hover: hover) {
        &:hover {
          background-color: var(--base-color-brand);
          color: var(--color-white);
          max-height: 258px;
          padding: 2rem 1rem 3.2rem;

          > .label {
            max-height: 258px;
          }
        }
      }

      &::before {
        position: absolute;
        bottom: 6px;
        left: 6px;
        display: inline-block;
        content: '';
        width: 42px;
        height: 42px;
        background: var(--color-white);
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        bottom: 17px;
        left: 50%;
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        background: var(--base-color-brand);
        mask-image: url('/arrow-download.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        transform: translateX(-50%);
      }

      > .label {
        @include text-small(bold);

        display: inline-block;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        color: inherit;
        max-height: 0;
        word-break: initial;
        hyphens: none;
        overflow: hidden;
        margin-bottom: 1rem;
        transition: var(--base-transition-all);
      }
    }
  }

  .layout-default.-page-downloads + & {
    opacity: 0;
    transform: translateY(5rem);
  }
}
</style>
