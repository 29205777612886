<template>
  <div class="error">
    <SiteHeader :key="locale" class="header" />

    <main class="layout-default -error-404">
      <div class="container -was-in-viewport" ref="containerElements">
        <!--        <button @click="handleError()">Clear error</button>-->
        <div class="wrapper -background-white error-404">
          <h1 class="title">{{ dataError404.error404.title }}</h1>
          <h2 class="subtitle">{{ dataError404.error404.subtitle }}</h2>
          <StructuredText
            v-if="dataError404.error404.copy"
            :data="dataError404.error404.copy"
            :render-block="renderBlock"
            :custom-mark-rules="customMarkRules"
          />
          <!--          <strong class="copy">tVersuchen Sie es mit..:</strong>-->
          <!--          <ul class="list">-->
          <!--            <li>tÜberprüfen Sie, ob die</li>-->
          <!--            <li>tÜberprüfen Sie, ob die</li>-->
          <!--            <li>tÜberprüfen Sie, ob die</li>-->
          <!--            <li>tÜberprüfen Sie, ob die</li>-->
          <!--          </ul>-->
        </div>
      </div>
    </main>

    <SiteFooter :key="locale" class="footer" />
  </div>
</template>
<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import renderBlock from '~/datocms/renderBlock';
import { customMarkRules } from '~/utils/customMarkRules';

const { data: dataError404 } = await useAsyncGql({
  operation: 'GetError404'
});

function handleError() {
  clearError({ redirect: '/' });
}
</script>
