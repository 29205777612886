<script setup lang="ts">
import MainNavigation from '~/components/site-header/MainNavigation.vue';
import LangSwitch from '~/components/site-header/LangSwitch.vue';

const route = useRoute();
const navOpen = ref<boolean>(false);
const navContainer = ref(null);
let isVisible = ref<boolean>(true);
let isInitial = ref<boolean>(true);
let scrollPosition = 0;
const isScrollingDown = () => window.top.scrollY > scrollPosition;
const isAtTheTop = () => window.top.scrollY === 0;
const isAboveThreshold = () => window.top.scrollY > navContainer.value.clientHeight + 300;

watch(
  () => route.fullPath,
  () => {
    navOpen.value = false;
  }
);

async function toggleNav() {
  navOpen.value = !navOpen.value;
}

const { locale, localeProperties } = useI18n();
const localePath = useLocalePath();

const { data, error, refresh } = await useAsyncGql({
  operation: 'GetHeader',
  variables: { locale: localeProperties.value.siteLocale }
});

const setBodyPaddingTop = () => {
  if (navOpen.value) return;

  const navHeight = navContainer.value.clientHeight;

  document.body.style.setProperty('--body-padding-top', `${navHeight}px`);
};

const onScroll = () => {
  if (navOpen.value) return;

  isVisible.value = !isScrollingDown();

  if (!isAboveThreshold()) {
    isVisible.value = true;
  }

  isInitial.value = isAtTheTop();

  scrollPosition = window.top.scrollY;
};

onMounted(() => {
  setBodyPaddingTop();

  window.addEventListener('resize', setBodyPaddingTop);
  window.addEventListener('scroll', onScroll);
});

onUnmounted(() => {
  window.removeEventListener('resize', setBodyPaddingTop);
  window.removeEventListener('scroll', onScroll);
});
</script>

<template>
  <header
    class="site-header-container"
    :class="{
      '-nav-open': navOpen,
      '-visible': isVisible,
      '-initial': isInitial
    }"
    ref="navContainer"
  >
    <div class="meta-header">
      <a class="link" href="https://swica.ch"> swica.ch </a>
      <LangSwitch class="languages" />
    </div>
    <div class="site-header">
      <div class="titlebar">
        <div class="title">
          <button
            class="toggle"
            :class="{ '-nav-is-open': navOpen }"
            type="button"
            @click="toggleNav()"
          >
            <img class="burger" src="/menu-burger.svg" aria-hidden="true" />
            <span class="closewrapper">
              <img class="close" src="/menu-close.svg" aria-hidden="true" />
            </span>
            <span class="label">Open/Close Navigation</span>
          </button>
          <NuxtLink class="homelink" :to="`/${locale}`">
            {{ data.header.title }} <span class="suffix">{{ data.header.titleSuffix }}</span>
          </NuxtLink>
        </div>
        <NuxtLink class="branding" :to="`/${locale}`">
          <img
            class="logoprefix -de"
            src="/logo-swica-prefix-de.svg"
            alt="Weil Gesundheit Alles ist"
            v-if="locale === 'de'"
          />
          <img
            class="logoprefix -fr"
            src="/logo-swica-prefix-fr.svg"
            alt="Parce que la santé passe avant tout"
            v-if="locale === 'fr'"
          />
          <img
            class="logoprefix -it"
            src="/logo-swica-prefix-it.svg"
            alt="Perché la salute è tutto"
            v-if="locale === 'it'"
          />
          <img class="logo" src="/logo-swica.svg" alt="Swica Logo" />
        </NuxtLink>
      </div>
      <div class="navigation">
        <MainNavigation />
      </div>
      <LangSwitch class="languages" />
    </div>
  </header>
</template>

<style lang="scss">
body:has(.site-header-container) {
  @media (--sm) {
    padding-top: var(--body-padding-top, clamp(74px, 14vw, 90px));
  }

  @media (--md) {
    padding-top: var(--body-padding-top, min(16vw, 110px));
  }

  @media (--lg) {
    padding-top: var(--body-padding-top, max(14vw, 160px));
  }

  @media (--xl) {
    padding-top: var(--body-padding-top, min(12.5vw, 196px));
  }
}

body:has(.site-header-container.-nav-open) {
  @media (--lg-down) {
    overflow: hidden;
  }
}

.site-header-container {
  background-color: var(--color-white);
  box-shadow: 0 0 1.25rem #00000026;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-header);
  transform: translateY(-100%);
  transition: transform var(--base-transition-duration) var(--base-transition-easing);

  &.-visible {
    transform: translateY(0);
  }

  @media (--lg-down) {
    &.-nav-open {
      min-height: 100dvh;
      overflow: scroll;
    }
  }
}

.meta-header {
  @extend %grid;

  border-bottom: 1px solid #f8f8f8;
  font-size: 0.875rem;
  padding-block: 0.5rem;

  @media (--lg-down) {
    display: none;
  }

  > .link {
    grid-column: grid-start / 10;
    color: var(--base-color-foreground-light);
    text-decoration: none;
  }

  > .languages {
    grid-column: 12 / grid-end;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    > .item {
      text-transform: uppercase;

      > a {
        text-decoration: none;
        color: var(--base-color-foreground-light);

        &[aria-current] {
          color: var(--base-color-brand);
        }
      }
    }
  }
}

.site-header {
  @extend %grid;

  @media (--lg-down) {
    .site-header-container.-nav-open & {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100dvh;
      z-index: var(--z-header);
      padding-bottom: var(--height-download-button, 0);
      overflow-y: scroll;
    }

    .site-header-container:not(.-nav-open) & > .navigation,
    .site-header-container:not(.-nav-open) & > .languages {
      display: none;
    }
  }

  > .titlebar {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-top: 1.375rem;

    @media (--lg-down) {
      padding-inline: calc(var(--grid-outer-margin) + 1rem);
      padding-bottom: var(--grid-outer-margin);
      background-color: var(--color-white);
    }

    > .title {
      display: flex;
      align-items: center;

      @media (--lg-up) {
        @include font-weight(bold);

        font-size: 2.625rem;
      }

      > .homelink {
        text-decoration: none;

        &:active,
        &:visited,
        &:hover {
          color: inherit;
        }
      }

      > .homelink > .suffix {
        @media (max-width: 369px) {
          display: block;
        }
      }

      > .toggle {
        position: relative;
        appearance: none;
        background: none;
        border: none;
        margin-left: -0.9rem;
        margin-right: 0.5rem;

        @media (--lg-up) {
          display: none;
        }

        &.-nav-is-open {
          > .burger {
            opacity: 0;
            rotate: 45deg;
          }
        }

        &:not(.-nav-is-open) {
          > .closewrapper > .close {
            opacity: 0;
            rotate: -45deg;
          }
        }

        > .burger,
        > .closewrapper > .close {
          width: 2.5rem;
          height: 2.5rem;
          transition: var(--base-transition-all);
          transform-origin: center center;
        }

        > .closewrapper {
          width: 2.5rem;
          height: 2.5rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        > .label {
          @include hide-visually;
        }
      }
    }

    > .branding {
      display: inline-flex;
      justify-content: flex-end;
      margin-left: auto;

      > .logoprefix,
      > .logo {
        @media (--sm) {
          height: 2.125rem;
        }

        @media (--md) {
          height: 2.375rem;
        }

        @media (--lg) {
          height: 2.75rem;
        }

        @media (--xl) {
          height: 3.125rem;
        }
      }

      > .logoprefix {
        @media (--lg-down) {
          display: none;
        }
      }
    }
  }

  > .titlebar,
  > .navigation {
    @media (--lg-down) {
      grid-column: full-bleed;
    }
  }

  > .navigation {
    @media (--lg-down) {
      background-color: var(--base-color-background);
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  > .languages {
    @media (--lg-up) {
      display: none;
    }

    background-color: var(--base-color-background);
    padding: calc(var(--grid-outer-margin) + 1rem);
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    > .item > a {
      text-decoration: none;
      text-transform: uppercase;
      color: var(--base-color-foreground-light);

      &[aria-current='page'] {
        color: var(--base-color-brand);
      }
    }
  }
}
</style>
