import { h } from 'vue';
import RichtextSocialRecord from '~/components/richtext/RichtextSocialRecord.vue';
import RichtextMedialinkRecord from '~/components/richtext/RichtextMedialinkRecord.vue';

// @ts-ignore
export default function renderBlock({ record, key }) {
  if (record.__typename === 'RichtextSocialRecord') {
    return h(RichtextSocialRecord, { data: record });
  }

  if (record.__typename === 'RichtextMedialinkRecord') {
    return h(RichtextMedialinkRecord, { data: record });
  }

  return null;
}
