<script setup lang="ts">
import renderBlock from '~/datocms/renderBlock';
import { Image, StructuredText } from 'vue-datocms';
import { customMarkRules } from '~/utils/customMarkRules';

const { localeProperties } = useI18n();

const { data } = await useAsyncGql({
  operation: 'GetFooter',
  variables: { locale: localeProperties.value.siteLocale }
});
</script>

<template>
  <div class="footer-wrapper" data-in-viewport v-if="data && data.footer">
    <footer class="site-footer">
      <div class="container">
        <p class="slogan">{{ data.footer.title }}</p>
        <h2 class="title">{{ data.footer.hotlineSectionTitle }}</h2>

        <div class="site-footer-hotline">
          <img class="icon" src="/icon-hotline.svg" aria-hidden="true" />
          <div class="content">
            <h3 class="title">{{ data.footer.hotlineTitle }}</h3>
            <p class="text">
              <a class="link" :href="`tel:${data.footer.hotlineNumber.replace(/\s+/g, '')}`">
                {{ data.footer.hotlineNumber }}
              </a>
            </p>
          </div>
        </div>

        <address class="address">
          <StructuredText
            v-if="data.footer.address"
            :data="data.footer.address"
            :render-block="renderBlock"
            :custom-mark-rules="customMarkRules"
          />
        </address>

        <div class="socialmedia">
          <ul class="social-media-list">
            <li class="item" v-for="item in data.footer.socialLinks" :key="item.key">
              <a
                class="link"
                :href="item.link.link"
                :target="item.link.openLinkInNewWindow ? '_blank' : ''"
              >
                <span class="label">{{ item.link.linkText }}</span>
                <Image
                  class="icon"
                  v-if="item.image && item.image.responsiveImage"
                  :data="item.image.responsiveImage"
                />
                <img
                  v-else-if="item.image && item.image.url"
                  class="icon"
                  :src="item.image.url"
                  :alt="item.image.alt ?? ''"
                />
              </a>
            </li>
          </ul>
        </div>

        <div class="site-footer-footnote">
          <div class="copyright">
            {{ data.footer.copyrightInformation }}
          </div>
          <div class="link" v-for="item in data.footer.legalInformationLinks" :key="item.id">
            <a :href="item.link" :target="item.openLinkInNewWindow ? '_blank' : ''">
              {{ item.linkText }}
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.footer-wrapper {
  overflow: hidden;

  // Animation
  @media (prefers-reduced-motion: no-preference) {
    @keyframes container-appear {
      0% {
        transform: translateY(100vh);
        opacity: 0;
        pointer-events: none;
      }

      1% {
        opacity: 1;
        pointer-events: initial;
      }

      100% {
        0% {
          transform: translateY(0);
        }
      }
    }

    &[data-in-viewport] {
      > .site-footer {
        transform: translateY(100vh);
        transition: transform 1s cubic-bezier(0, 0, 1, 0.11);
      }

      &.-is-in-viewport > .site-footer {
        transform: translateY(0);
        transition: transform 1s cubic-bezier(0, 0, 0.11, 0.99);
      }
    }
  }
}

.site-footer {
  @extend %grid;

  @include module-full-bleed-background-color(var(--base-color-brand));

  @media (--sm) {
    padding: 1.562rem 1.562rem 2.875rem;
  }

  @media (--md-lg) {
    padding: 2.5rem 4.125rem 1.125rem;
  }

  @media (--xl) {
    padding: 2.5rem 8.562rem 1.125rem;
  }

  a {
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }

  > .container {
    color: var(--color-white);

    > .slogan {
      @include font-weight(300);

      text-transform: uppercase;

      @media (--sm) {
        font-size: 1.375rem;
      }

      @media (--md-up) {
        font-size: 2rem;
      }
    }

    > .slogan + .title,
    .site-footer-hotline,
    .address,
    .socialmedia,
    .site-footer-footnote {
      @media (--sm) {
        margin-top: 1.375rem;
      }

      @media (--md-up) {
        margin-top: 2rem;
      }
    }

    > .title {
      @include text-base(bold);
    }
  }
}

.site-footer-hotline {
  display: flex;

  > .icon {
    margin-right: 1rem;
    width: 1.5rem;
    align-self: flex-start;
    padding-top: 0.25em;
  }

  > .content {
    > .title {
      @include text-base(bold);
    }

    > .text {
      @include text-small;
    }
  }
}

.social-media-list {
  display: flex;
  flex-wrap: wrap;
  margin: -1.4375rem;

  > .item {
    margin: 1.4375rem;
  }

  > .item > .link {
    > .label {
      @include hide-visually;
    }
  }

  > .item > .link > .icon {
    width: 1.875rem;
    height: 1.875rem;
    aspect-ratio: 1 / 1;
  }
}

.site-footer-footnote {
  @include text-extra-small;

  @media (--lg-down) {
    display: grid;
    gap: 0.5rem;
  }

  @media (--lg-up) {
    display: flex;
  }

  > * {
    + * {
      @media (--lg-up) {
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
