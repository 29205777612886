<template>
  <ul class="lang-switch">
    <li class="item" v-for="locale in locales" :key="locale.code">
      <NuxtLink :to="getTranslatedPath(locale.code)">
        {{ locale.code }}
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
const i18n = useI18n();
const { locale, locales } = useI18n();

const route = useRoute();

const { data } = await useAsyncGql({
  operation: 'GetTranslatedSlugs'
});

const paths = computed(function () {
  const items: {
    name: string;
    translations: {
      de: string | null;
      fr: string | null;
      it: string | null;
    };
  }[] = [];

  for (let i = 0; i < data.value.allPages.length; i++) {
    const item = data.value.allPages[i];

    const obj = {
      name: String(item.slug),
      translations: {
        de: '',
        fr: '',
        it: ''
      }
    };

    item._allTranslatedSlugLocales?.forEach((it) => {
      const loc = it.locale?.substring(0, 2);

      if (it.value && (loc === 'de' || loc === 'fr' || loc === 'it')) {
        obj.translations[loc] = it.value ?? null;
      }
    });

    items.push(obj);
  }
  return items;
});

const currentPaths = computed(function () {
  const currentSlug = route.params.slug;
  const currentLocale = locale.value as 'it' | 'de' | 'fr';

  if (!currentSlug) {
    return {
      de: '/de',
      fr: '/fr',
      it: '/it'
    };
  }

  const obj = paths.value.find((it) => it.translations[currentLocale] === currentSlug);

  if (!obj) {
    return {
      de: '/de',
      fr: '/fr',
      it: '/it'
    };
  }

  return {
    de: `/de/${obj.translations.de}`,
    fr: `/fr/${obj.translations.fr}`,
    it: `/it/${obj.translations.it}`
  };
});

function getTranslatedPath(locale: string) {
  if (locale === 'de' || locale === 'fr' || locale === 'it') {
    return currentPaths.value[locale] ?? `/${locale}`;
  }

  return `/${locale}`;
}
</script>

<style scoped lang="scss"></style>
