<template>
  <a :href="url" target="_blank" class="richtext-medialink">
    {{ data.label }}
  </a>
</template>

<script setup lang="ts">
const props = defineProps<{
  data: {
    label: string;
    id: string;
    internalMedia: {
      url: string;
      id: string;
      video?: {
        mp4Url: string;
        alt: string;
      };
      responsiveImage?: {
        src: string;
      };
    };
  };
}>();

const url = computed(function () {
  if (!props.data.internalMedia) {
    return '#';
  }
  return (
    props.data.internalMedia.responsiveImage?.src ??
    props.data.internalMedia.video?.mp4Url ??
    props.data.internalMedia.url ??
    '#'
  );
});
</script>

<style scoped lang="scss">
.richtext-medialink {
  //padding: 2.5rem 0;
  //display: flex;
  //gap: 1.5rem;

  //> .link {
  //  display: inline-block;
  //  width: 2rem;
  //  height: 2rem;
  //  border: none;
  //  text-decoration: none;
  //  background: none;
  //  opacity: 1;
  //  transition: opacity 300ms;
  //
  //  &:hover {
  //    opacity: 0.8;
  //  }
  //
  //  > .text {
  //    position: absolute;
  //    z-index: -100;
  //    font-size: 0;
  //  }
  //
  //  > .icon {
  //    display: inline-block;
  //    width: 100%;
  //    height: 100%;
  //    background-size: 100% 100%;
  //    background-repeat: no-repeat;
  //  }
  //}
}
</style>
