<template>
  <nav class="main-navigation">
    <ul class="list">
      <li class="item" v-for="item in navLinks" :key="`${locale}-${item.id}`">
        <NuxtLink
          :to="
            getRouteObject(
              item.link.slug,
              item.link._allTranslatedSlugLocales ?? [],
              item.link.__typename
            )
          "
        >
          {{ item.link.title }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

type NavLink = {
  link: {
    __typename: string;
    id: string;
    title: string;
    slug: string;
    _allTranslatedSlugLocales: {
      value: string;
      locale: 'it_CH' | 'de_CH' | 'fr_CH';
    }[];
  };
  id: string;
};

const { locale, localeProperties } = useI18n();

const { data } = await useAsyncGql({
  operation: 'GetMainNavigation',
  variables: { locale: localeProperties.value.siteLocale }
});

const mainNavigation = data.value.mainNavigation;

const navLinks = computed(function (): NavLink[] {
  if (mainNavigation) {
    // @ts-ignore
    return mainNavigation.navLinks ?? ([] as NavLink[]);
  }

  return [];
});

function getRouteObject(
  slug: string,
  translatedSlugs: {
    value: string;
    locale: 'it_CH' | 'de_CH' | 'fr_CH';
  }[],
  __typename: string
) {
  const translatedSlug = translatedSlugs.find(
    (i) => i.locale === localeProperties.value.siteLocale
  )?.value;

  const route: Partial<RouteLocationRaw> = {
    name: '',
    params: {
      slug: translatedSlug || slug
    }
  };

  // console.log(locale.value, translatedSlug);

  if (slug === 'home') {
    route.name = `index___${locale.value}`;
    route.params = undefined;

    return route;
  } else if (__typename === 'PageRecord') {
    route.name = `slug___${locale.value}`;
  }

  return route;
}
</script>

<style lang="scss">
.main-navigation {
  @media (--lg-down) {
    padding-bottom: var(--grid-outer-margin);

    > .list {
      display: flex;
      flex-direction: column;

      > .item {
        margin: 0.688rem 0.625rem 0;
      }

      > .item > a {
        @include font-weight(bold);

        display: block;
        background-color: var(--color-white);
        border-radius: var(--base-border-radius);
        box-shadow: 0 0 1.25rem #00000026;
        padding: 0.36rem 1.5rem;
        font-size: 1.75rem;
        line-height: 1.214;
        text-decoration: none;

        &[aria-current] {
          background-color: var(--base-color-brand);
          color: var(--color-white);
        }
      }
    }

    // Animation
    @media (prefers-reduced-motion: no-preference) {
      @keyframes nav-item-appear {
        from {
          opacity: 0;
          transform: translateY(50%);
        }

        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .site-header-container.-nav-open & > .list > .item {
        animation: nav-item-appear 1s backwards cubic-bezier(0, 0, 0.17, 0.99);

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            animation-delay: $i * 0.075s;
          }
        }
      }
    }
  }

  @media (--lg-up) {
    > .list {
      display: flex;
      gap: 1.562rem;

      > .item {
        padding-block: 1.25rem;
        border-bottom: 2px solid transparent;

        &:has(a[aria-current]) {
          color: var(--base-color-brand);
          border-color: currentColor;
        }

        @media (hover: hover) {
          &:hover {
            border-color: currentColor;
          }
        }
      }

      > .item > a {
        @include font-weight(bold);

        text-decoration: none;
        font-size: 1.062rem;
        color: currentColor;
      }
    }
  }
}
</style>
