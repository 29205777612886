import { renderMarkRule } from 'vue-datocms';
import HTMLEntity from '~/components/HTMLEntity.vue';

export const customMarkRules = [
  renderMarkRule('code', ({ adapter: { renderNode: h }, mark, children, key }) => {
    const content = children?.[0];

    if (
      content.includes('&shy;') ||
      content.includes('&zwj;') ||
      content.includes('&nbsp;') ||
      content.includes('<br>') ||
      isWrappedInSubTag(content) ||
      isWrappedInSupTag(content)
    ) {
      return h(HTMLEntity, { key, content });
    }

    return h('code', { key }, children);
  })
];

function isWrappedInSupTag(input: string) {
  const supRegex = /^<sup>.*<\/sup>$/;
  return supRegex.test(input);
}

function isWrappedInSubTag(input: string) {
  const supRegex = /^<sub>.*<\/sub>$/;
  return supRegex.test(input);
}
