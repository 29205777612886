<template>
  <div class="app sticky-footer">
    <SiteHeader :key="locale" class="header"/>
    <NuxtPage class="main"/>
    <DownloadLink class="download-link"/>
    <SiteFooter :key="locale" class="footer"/>
  </div>
</template>


<script setup lang="ts">
import SiteHeader from "~/components/site-header/SiteHeader.vue";
import SiteFooter from "~/components/site-footer/SiteFooter.vue";
import DownloadLink from "~/components/partials/DownoadLink.vue";

const {localeProperties, locale, setLocaleMessage} = useI18n()


useHead({
  htmlAttrs: {
    lang: localeProperties.value.iso,
    dir: 'ltr'
  },
})

const {data: dataGeneral} = await useAsyncGql({
  operation: 'GetGeneral',
});

if(dataGeneral.value.general){
  // @ts-ignore
  setLocalMessagesFromGeneralData(dataGeneral.value.general)
}

function setLocalMessagesFromGeneralData(data: Record<string, {
  locale: 'de_CH' | 'fr_CH' | 'it_CH';
  value: string;
}[]>) {

  for (const key in data) {
    const translations = data[key]

    for (let i = 0; i < translations.length; i++) {
      const t = translations[i];

      const obj: Record<string, string> = {}
      obj[key] = t.value

      setLocaleMessage(t.locale.substring(0, 2), obj)
    }
  }
}

</script>

<style lang="scss">
.app:not(:has(.layout-default.-page-downloads)) {
  @media (--lg-down) {
    --height-download-button: 2.875rem;
  }
}

.page-enter-active,
.page-leave-active {
  transition: var(--base-transition-all-slow);
  transform-origin: top center;
}

.page-enter-from {
  transform: translateY(100vh);
}

.page-leave-to {
  transform: scale(0.8);
  opacity: 0;
}
</style>
