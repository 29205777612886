import { default as _91slug_932nInfs9uDBMeta } from "/Users/aliceloher/Repos/swica-report-2023/pages/[slug].vue?macro=true";
import { default as indexqhSl2HY2PiMeta } from "/Users/aliceloher/Repos/swica-report-2023/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_932nInfs9uDBMeta?.name ?? "slug___de___default",
    path: _91slug_932nInfs9uDBMeta?.path ?? "/:slug()",
    meta: _91slug_932nInfs9uDBMeta || {},
    alias: _91slug_932nInfs9uDBMeta?.alias || [],
    redirect: _91slug_932nInfs9uDBMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932nInfs9uDBMeta?.name ?? "slug___de",
    path: _91slug_932nInfs9uDBMeta?.path ?? "/de/:slug()",
    meta: _91slug_932nInfs9uDBMeta || {},
    alias: _91slug_932nInfs9uDBMeta?.alias || [],
    redirect: _91slug_932nInfs9uDBMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932nInfs9uDBMeta?.name ?? "slug___fr",
    path: _91slug_932nInfs9uDBMeta?.path ?? "/fr/:slug()",
    meta: _91slug_932nInfs9uDBMeta || {},
    alias: _91slug_932nInfs9uDBMeta?.alias || [],
    redirect: _91slug_932nInfs9uDBMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932nInfs9uDBMeta?.name ?? "slug___it",
    path: _91slug_932nInfs9uDBMeta?.path ?? "/it/:slug()",
    meta: _91slug_932nInfs9uDBMeta || {},
    alias: _91slug_932nInfs9uDBMeta?.alias || [],
    redirect: _91slug_932nInfs9uDBMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqhSl2HY2PiMeta?.name ?? "index___de___default",
    path: indexqhSl2HY2PiMeta?.path ?? "/",
    meta: indexqhSl2HY2PiMeta || {},
    alias: indexqhSl2HY2PiMeta?.alias || [],
    redirect: indexqhSl2HY2PiMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhSl2HY2PiMeta?.name ?? "index___de",
    path: indexqhSl2HY2PiMeta?.path ?? "/de",
    meta: indexqhSl2HY2PiMeta || {},
    alias: indexqhSl2HY2PiMeta?.alias || [],
    redirect: indexqhSl2HY2PiMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhSl2HY2PiMeta?.name ?? "index___fr",
    path: indexqhSl2HY2PiMeta?.path ?? "/fr",
    meta: indexqhSl2HY2PiMeta || {},
    alias: indexqhSl2HY2PiMeta?.alias || [],
    redirect: indexqhSl2HY2PiMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhSl2HY2PiMeta?.name ?? "index___it",
    path: indexqhSl2HY2PiMeta?.path ?? "/it",
    meta: indexqhSl2HY2PiMeta || {},
    alias: indexqhSl2HY2PiMeta?.alias || [],
    redirect: indexqhSl2HY2PiMeta?.redirect,
    component: () => import("/Users/aliceloher/Repos/swica-report-2023/pages/index.vue").then(m => m.default || m)
  }
]