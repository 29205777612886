import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetError404","GetFooter","GetGeneral","GetHeader","GetLocales","GetMainNavigation","GetPageBySlug","GetPageByTranslatedSlug","GetSlugsPageDownload","GetTranslatedSlugs"]}
export const GqlGetError404 = (...params) => useGql()('GetError404', ...params)
export const GqlGetFooter = (...params) => useGql()('GetFooter', ...params)
export const GqlGetGeneral = (...params) => useGql()('GetGeneral', ...params)
export const GqlGetHeader = (...params) => useGql()('GetHeader', ...params)
export const GqlGetLocales = (...params) => useGql()('GetLocales', ...params)
export const GqlGetMainNavigation = (...params) => useGql()('GetMainNavigation', ...params)
export const GqlGetPageBySlug = (...params) => useGql()('GetPageBySlug', ...params)
export const GqlGetPageByTranslatedSlug = (...params) => useGql()('GetPageByTranslatedSlug', ...params)
export const GqlGetSlugsPageDownload = (...params) => useGql()('GetSlugsPageDownload', ...params)
export const GqlGetTranslatedSlugs = (...params) => useGql()('GetTranslatedSlugs', ...params)